
import { api } from "@/api/api";
import { ApiGetCustomerOrganizationAddressListDto, ApiSortOrder } from "@/api/generated/Api";
import RegisterOrganizationModal from "@/components/contacts/RegisterOrganizationModal.vue";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { getInitialModalData, useOpenModal } from "@/fragments/modal/useOpenModal";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { PaginationValues } from "@/shared/interfaces/TablePaginationInterface";
import { computed, defineComponent, ref, watch } from "@vue/composition-api";
import debounce from "lodash.debounce";
import { DataOptions } from "vuetify";
import { VSwitch, VTextField } from "vuetify/lib";

interface PaginationQueryInterface {
  Name?: string;
  OrgNumber?: string;
  Email?: string;
  MobileNumber?: string;
  IncludeInactive?: boolean;
  PageNumber?: number;
  PageSize?: number;
  SortBy?: string;
  SortOrder?: ApiSortOrder;
}

export default defineComponent({
  name: "ContactsOrganisationPage",
  components: {
    BaseTableFiltered,
    BaseModal,
    RegisterOrganizationModal,
    BaseLayout,
  },
  setup() {
    const pageState = ref<DataOptions>({
      page: 1,
      itemsPerPage: 30,
      sortBy: [],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
      sortDesc: [],
    });
    const query = ref<PaginationQueryInterface>({
      PageNumber: pageState.value.page,
      PageSize: pageState.value.itemsPerPage,
    });
    const paginationValues = ref<PaginationValues>({
      isLoading: false,
      pageCount: 0,
      serverItemsLength: 0,
    });
    const modalData = ref(getInitialModalData());
    const organizations = ref<ApiGetCustomerOrganizationAddressListDto[]>([]);

    watch(
      query,
      () => {
        debounceFetch();
      },
      { deep: true }
    );

    const updateQuery = (key: string, value?: string) => {
      query.value = {
        ...query.value,
        PageNumber: 1,
        [key]: value,
      };
      pageState.value.page = 1;
    };

    const filter = computed(() => [
      {
        component: VTextField,
        value: "name",
        default: undefined,
        attrs: {
          label: "Firmanavn",
          dense: true,
          hideDetails: true,
          clearable: true,
        },
        apply: (value: any, model: any) => {
          if ((!model && !query.value.Name) || query.value.Name === model) {
            return;
          }
          if (model && model.length >= 2) {
            updateQuery("Name", model);
          } else if (!model || model.length === 0) {
            updateQuery("Name");
          }
        },
      },
      {
        component: VTextField,
        value: "orgNumber",
        default: undefined,
        attrs: {
          label: "Organisasjonsnummer",
          dense: true,
          hideDetails: true,
          clearable: true,
        },
        apply: (value: any, model: any) => {
          if ((!model && !query.value.OrgNumber) || query.value.OrgNumber === model) {
            return;
          }
          if (model && model.length >= 2) {
            updateQuery("OrgNumber", model);
          } else if (!model || model.length === 0) {
            updateQuery("OrgNumber");
          }
        },
      },
      {
        component: VTextField,
        value: "email",
        default: undefined,
        attrs: {
          label: "E-post",
          dense: true,
          hideDetails: true,
          clearable: true,
        },
        apply: (value: any, model: any) => {
          if ((!model && !query.value.Email) || query.value.Email === model) {
            return;
          }
          if (model && model.length >= 2) {
            updateQuery("Email", model);
          } else if (!model || model.length === 0) {
            updateQuery("Email");
          }
        },
      },
      {
        component: VTextField,
        value: "mobileNumber",
        default: undefined,
        attrs: {
          label: "Mobilnummer",
          dense: true,
          hideDetails: true,
          clearable: true,
        },
        apply: (value: any, model: any) => {
          if ((!model && !query.value.MobileNumber) || query.value.MobileNumber === model) {
            return;
          }

          if (model && model.length >= 2) {
            updateQuery("MobileNumber", model);
          } else if (!model || model.length === 0) {
            updateQuery("MobileNumber");
          }
        },
      },
      {
        component: VSwitch,
        value: "isActive",
        default: false,
        staticClass: "mx-3",
        attrs: {
          inset: true,
          label: "Vis inaktive",
        },
        apply: (value: any, model: any) => (query.value.IncludeInactive = model || false),
      },
    ]);

    const updatePagination = (paginationData: any) => {
      pageState.value = paginationData;
      query.value = {
        ...query.value,
        PageNumber: pageState.value.page,
        PageSize: pageState.value.itemsPerPage > 0 ? pageState.value.itemsPerPage : undefined,
        SortBy: pageState.value.sortBy[0], // API doesn't support multi-sort. Multi-sort is disabled on the table
        SortOrder:
          pageState.value.sortDesc.length === 0
            ? undefined
            : pageState.value.sortDesc[0]
            ? ApiSortOrder.ApiDescending
            : ApiSortOrder.ApiAscending,
      };
    };

    const addOrganization = useOpenModal(ModalType.Add, "organisasjon", modalData);

    const displayOrganization = useOpenModal(ModalType.Display, "organisasjon", modalData);

    const editOrganization = useOpenModal(ModalType.Edit, "organisasjon", modalData);

    const fetchOrganizations = async () => {
      paginationValues.value.isLoading = true;
      const response = await api.customer.getCustomerOrganizationAddressList(query.value);
      paginationValues.value = {
        isLoading: false,
        pageCount: response.data.totalPages,
        serverItemsLength: response.data.totalCount,
      };
      organizations.value = response.data.items || [];
    };

    const debounceFetch = debounce(fetchOrganizations, 500);

    return {
      headers,
      organizations,
      addOrganization,
      displayOrganization,
      editOrganization,
      closeModal: () => (modalData.value.showModal = false),
      filter,
      fetchOrganizations,
      updatePagination,
      pageState,
      paginationValues,
      modalData,
      query,
    };
  },
});

const headers = [
  {
    text: "Navn",
    value: "name",
  },
  {
    text: "Handlinger",
    value: "actions",
    sortable: false,
  },
  {
    text: "Organisasjonsnummer",
    value: "orgNumber",
  },
  {
    text: "E-post",
    value: "email",
  },
  {
    text: "Mobilnummer",
    value: "mobileNumber",
  },
  {
    text: "Status",
    value: "isActive",
  },
];
