var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.modalData.showModal ? _c('BaseModal', {
    on: {
      "close": function close($event) {
        return _vm.closeModal();
      }
    }
  }, [_c('RegisterOrganizationModal', {
    attrs: {
      "organizationId": _vm.modalData.existingItemId,
      "headline": _vm.modalData.modalHeadline,
      "type": _vm.modalData.modalType
    },
    on: {
      "close": function close($event) {
        return _vm.closeModal();
      },
      "refetch": function refetch($event) {
        return _vm.fetchOrganizations();
      }
    }
  })], 1) : _vm._e(), _c('BaseLayout', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', {
          attrs: {
            "data-cy": "titleAdressebokOrganization"
          }
        }, [_vm._v(" Adressebok - Firma ")])];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "color": "white",
            "outlined": "",
            "data-cy": "addOrganization"
          },
          on: {
            "click": _vm.addOrganization
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-plus")]), _vm._v(" Legg til organisasjon ")], 1)];
      },
      proxy: true
    }])
  }, [[_c('v-form', [_c('BaseTableFiltered', {
    staticClass: "mt-3",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.organizations,
      "filter": _vm.filter,
      "options": _vm.pageState,
      "pageCount": _vm.paginationValues.pageCount,
      "server-items-length": _vm.paginationValues.serverItemsLength,
      "loading": _vm.paginationValues.isLoading,
      "multiSort": false,
      "hasServerSidePagination": true
    },
    on: {
      "update:options": _vm.updatePagination
    },
    scopedSlots: _vm._u([{
      key: "item.isActive",
      fn: function fn(_ref) {
        var value = _ref.value;
        return [_vm._v(" " + _vm._s(value ? "Aktiv" : "Inaktiv") + " ")];
      }
    }, {
      key: "actions",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref3) {
              var on = _ref3.on,
                  attrs = _ref3.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                staticClass: "mr-2",
                on: {
                  "click": function click($event) {
                    return _vm.displayOrganization(item, item.name);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-open-in-new ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Vis " + _vm._s(item.name))])]), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref4) {
              var on = _ref4.on,
                  attrs = _ref4.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                staticClass: "mr-2",
                on: {
                  "click": function click($event) {
                    return _vm.editOrganization(item, item.name);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-pencil ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Rediger " + _vm._s(item.name))])])];
      }
    }], null, true)
  })], 1)]], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }