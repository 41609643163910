var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isLoading ? _c('v-overlay', {
    attrs: {
      "absolute": "",
      "color": "white",
      "opacity": ".7"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "color": "primary"
    }
  })], 1) : _c('BaseModalForm', {
    attrs: {
      "editable": _vm.type !== _vm.ModalType.Display,
      "headline": _vm.headline,
      "modalType": _vm.type
    },
    on: {
      "submit": _vm.handleSubmit,
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    },
    model: {
      value: _vm.validForm,
      callback: function callback($$v) {
        _vm.validForm = $$v;
      },
      expression: "validForm"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "ma-0"
  }, [_vm._v("Firmainformasjon")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "label": "Navn",
      "rules": _vm.validateNotEmpty,
      "name": "firstname",
      "type": "text",
      "data-cy": "registerName",
      "dense": "",
      "required": ""
    },
    model: {
      value: _vm.currentOrganization.customer.name,
      callback: function callback($$v) {
        _vm.$set(_vm.currentOrganization.customer, "name", $$v);
      },
      expression: "currentOrganization.customer.name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "label": "Organisasjonsnummer",
      "rules": (_vm.validateNotEmpty, _vm.validateIsOrganizationNumber),
      "name": "lastname",
      "type": "text",
      "data-cy": "registerOrgNo",
      "dense": "",
      "required": ""
    },
    model: {
      value: _vm.currentOrganization.customer.orgNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.currentOrganization.customer, "orgNumber", $$v);
      },
      expression: "currentOrganization.customer.orgNumber"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "label": "Hjemmeside",
      "data-cy": "registerHomePage",
      "dense": ""
    },
    model: {
      value: _vm.currentOrganization.customer.homePage,
      callback: function callback($$v) {
        _vm.$set(_vm.currentOrganization.customer, "homePage", $$v);
      },
      expression: "currentOrganization.customer.homePage"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "ma-0"
  }, [_vm._v("Kontaktinformasjon")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "label": "E-post",
      "rules": _vm.currentOrganization.email ? _vm.validateIsEmail : [],
      "name": "email",
      "type": "email",
      "data-cy": "registerEmail",
      "dense": "",
      "required": ""
    },
    model: {
      value: _vm.currentOrganization.email,
      callback: function callback($$v) {
        _vm.$set(_vm.currentOrganization, "email", $$v);
      },
      expression: "currentOrganization.email"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "label": "Mobilnummer",
      "rules": _vm.validateIsNorwegianPhoneNumber,
      "name": "mobile",
      "type": "tel",
      "data-cy": "registermobileNumber",
      "dense": "",
      "required": ""
    },
    model: {
      value: _vm.currentOrganization.mobileNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.currentOrganization, "mobileNumber", $$v);
      },
      expression: "currentOrganization.mobileNumber"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "ma-0"
  }, [_vm._v(" Adresse ")]), _c('span', {
    staticClass: "addressSwitcher"
  }, [_c('v-switch', {
    attrs: {
      "readonly": _vm.readonly,
      "value": false,
      "inset": "",
      "label": _vm.sameAsInvoiceAddress ? 'Bruk samme for fakturaadresse' : 'Spesifiser fakturaadresse separat'
    },
    model: {
      value: _vm.sameAsInvoiceAddress,
      callback: function callback($$v) {
        _vm.sameAsInvoiceAddress = $$v;
      },
      expression: "sameAsInvoiceAddress"
    }
  })], 1)]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "label": "Adresse",
      "rules": _vm.validateNotEmpty,
      "name": "address",
      "type": "text",
      "data-cy": "registerAddress",
      "dense": "",
      "required": ""
    },
    model: {
      value: _vm.currentOrganization.postAddress1,
      callback: function callback($$v) {
        _vm.$set(_vm.currentOrganization, "postAddress1", $$v);
      },
      expression: "currentOrganization.postAddress1"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "label": "Postnr",
      "rules": (_vm.validateNotEmpty, _vm.validateIsNorwegianZipCode),
      "name": "zip",
      "type": "text",
      "data-cy": "registerPostCode",
      "dense": "",
      "required": ""
    },
    model: {
      value: _vm.currentOrganization.postZip,
      callback: function callback($$v) {
        _vm.$set(_vm.currentOrganization, "postZip", $$v);
      },
      expression: "currentOrganization.postZip"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "8"
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "label": "Poststed",
      "rules": _vm.validateNotEmpty,
      "name": "zipPlace",
      "type": "text",
      "data-cy": "registerPlace",
      "dense": "",
      "required": ""
    },
    model: {
      value: _vm.currentOrganization.postCity,
      callback: function callback($$v) {
        _vm.$set(_vm.currentOrganization, "postCity", $$v);
      },
      expression: "currentOrganization.postCity"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', {
    attrs: {
      "readonly": _vm.readonly,
      "label": "Land",
      "items": _vm.countryItems,
      "rules": _vm.validateNotEmpty,
      "item-text": "label",
      "item-value": "value",
      "required": "",
      "dense": ""
    },
    model: {
      value: _vm.currentOrganization.postCountry,
      callback: function callback($$v) {
        _vm.$set(_vm.currentOrganization, "postCountry", $$v);
      },
      expression: "currentOrganization.postCountry"
    }
  })], 1)], 1), !_vm.sameAsInvoiceAddress ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "ma-0"
  }, [_vm._v("Fakturaadresse")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "label": "Adresse",
      "rules": _vm.validateNotEmpty,
      "name": "invoiceAddress",
      "type": "text",
      "data-cy": "registerAddress",
      "dense": "",
      "required": ""
    },
    model: {
      value: _vm.currentOrganization.invoiceAddress1,
      callback: function callback($$v) {
        _vm.$set(_vm.currentOrganization, "invoiceAddress1", $$v);
      },
      expression: "currentOrganization.invoiceAddress1"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "label": "Postnr",
      "rules": _vm.validateIsNorwegianZipCode,
      "name": "zip",
      "type": "text",
      "data-cy": "registerPostCode",
      "dense": "",
      "required": ""
    },
    model: {
      value: _vm.currentOrganization.invoiceZip,
      callback: function callback($$v) {
        _vm.$set(_vm.currentOrganization, "invoiceZip", $$v);
      },
      expression: "currentOrganization.invoiceZip"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "8"
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "label": "Poststed",
      "rules": _vm.validateNotEmpty,
      "name": "zipPlace",
      "type": "text",
      "data-cy": "registerPlace",
      "dense": "",
      "required": ""
    },
    model: {
      value: _vm.currentOrganization.invoiceCity,
      callback: function callback($$v) {
        _vm.$set(_vm.currentOrganization, "invoiceCity", $$v);
      },
      expression: "currentOrganization.invoiceCity"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', {
    attrs: {
      "readonly": _vm.readonly,
      "label": "Land",
      "items": _vm.countryItems,
      "rules": _vm.validateNotEmpty,
      "item-text": "label",
      "item-value": "value",
      "required": "",
      "dense": ""
    },
    model: {
      value: _vm.currentOrganization.invoiceCountry,
      callback: function callback($$v) {
        _vm.$set(_vm.currentOrganization, "invoiceCountry", $$v);
      },
      expression: "currentOrganization.invoiceCountry"
    }
  })], 1)], 1) : _vm._e(), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-switch', {
    attrs: {
      "readonly": _vm.readonly,
      "value": false,
      "inset": "",
      "label": _vm.currentOrganization.isActive ? 'Aktiv' : 'Inaktiv'
    },
    model: {
      value: _vm.currentOrganization.isActive,
      callback: function callback($$v) {
        _vm.$set(_vm.currentOrganization, "isActive", $$v);
      },
      expression: "currentOrganization.isActive"
    }
  })], 1), _vm.type !== _vm.ModalType.Add ? _c('v-col', {
    staticClass: "py-0 text--secondary",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" Sist oppdatert " + _vm._s(_vm.currentOrganization.updateDate && _vm.formatRelative(_vm.currentOrganization.updateDate)) + " av " + _vm._s(_vm.currentOrganization.updatedByUser || "SYSTEM") + " ")]) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }